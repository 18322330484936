import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"

import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

import styled from "styled-components"
import font from "../fonts/fonts.module.scss"
import TextContent from "../components/service/textContent"
import SwiperNews from "../components/sliderSwiper/swiperNews"

const EscapeTemplate = ({ data, pageContext, location }) => {
  console.log(pageContext.capa.childImageSharp.fluid.src)
  return (
    <>
      <SEO
        title={pageContext.title}
        image={pageContext.capa.childImageSharp.fluid.src}
      />
      <Layout>
        {useBreakpoint().mobile ? (
          <>
            <HeaderMobile data={data.globalJson.header} services={true} />
            <BackgroundStyled
              bg={`url("${data.noticiasJson.introMobile.backgroundscroll.childImageSharp.fluid.src}")`}
            />

            <NewsStyled>
              <TextContent data={pageContext.content} title={pageContext} />
              <SwiperNews data={pageContext} />
              <FooterMobile
                data={data.globalJson.footer}
                long
                floatButtons={data.globalJson.floatButtons}
              />
            </NewsStyled>
          </>
        ) : (
          <>
            <Header data={data.globalJson.header} services={true} />
            <BackgroundStyled
              bg={`url("${data.noticiasJson.intro.backgroundscroll.childImageSharp.fluid.src}")`}
            />

            <NewsStyled>
              <TextContent data={pageContext.content} title={pageContext} />
              <SwiperNews data={pageContext} />

              <Footer
                data={data.globalJson.footer}
                long
                floatButtons={data.globalJson.floatButtons}
              />
            </NewsStyled>
          </>
        )}
      </Layout>
    </>
  )
}

export default EscapeTemplate

const BackgroundStyled = styled.div`
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: ${props => props.bg || ""};
  left: 0;
  right: 0;
  z-index: -99;
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
`

const NewsStyled = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
`

export const Json = graphql`
  query escapeTemplate {
    noticiasJson {
      intro {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundscroll {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      introMobile {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundscroll {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
      }
      floatButtons {
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
