import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import { Navigation, Pagination, Autoplay } from "swiper"
import styled from "styled-components"
import Img from "gatsby-image"

// Estilização usando styled-components
const SwiperNewsContainer = styled.div`
  width: 1200px;
  margin: 100px auto;

  height: 700px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1300px) {
    height: 500px;
    width: auto;
  }
  @media only screen and (max-width: 700px) {
    height: 326px;
    width: auto;
  }
`

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-slide {
    display: flex;

    .gatsby-image-wrapper,
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .swiper-pagination {
    bottom: 10px;
    display: flex;
    flex-direction: column;
    width: auto;
    gap: 15px;

    .swiper-pagination-bullet {
      width: 50px;
      height: 50px;
      background: none;
      border: none;
      position: relative;
      opacity: 1 !important;

      @media only screen and (max-width: 700px) {
        width: 15px;
        height: 28px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background: url("/images/assistencia/traco-3.svg") no-repeat center
          center;
        background-size: contain;
      }

      &.swiper-pagination-bullet-active:after {
        background: url("/images/assistencia/traco-2.svg") no-repeat center
          center;
        background-size: contain;
      }
    }
  }
`

const SwiperNews = ({ data }) => {
  console.log(data)
  return (
    <SwiperNewsContainer>
      <StyledSwiper
        modules={[Pagination]}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        pagination={{ clickable: true }}
      >
        {data.gallery.map((itemGallery, y) => (
          <SwiperSlide>
            <Img
              fluid={itemGallery.image.childImageSharp.fluid}
              alt={itemGallery.alt}
            />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </SwiperNewsContainer>
  )
}

export default SwiperNews
