import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const TextContent = ({ data, title }) => {
  return (
    <StyledTextContent>
      <>
        {title.title && <Title>{title.title}</Title>}
        {title.subtitle && <SubTitle>{title.subtitle}</SubTitle>}
        <Img
          fluid={title.imageTop.childImageSharp.fluid}
          className="image-top"
        />
        {data.map((inside, x) => (
          <div>
            {inside.title && <SubText>{inside.title}</SubText>}
            {inside.text && (
              <Text dangerouslySetInnerHTML={{ __html: inside.text }} />
            )}
            {inside.image && (
              <Img
                fluid={inside.image.childImageSharp.fluid}
                alt={inside.title}
              />
            )}
          </div>
        ))}
      </>
    </StyledTextContent>
  )
}

export default TextContent

const Title = styled.h1``

const SubTitle = styled.h2``

const SubText = styled.h3``

const Text = styled.p``

const StyledTextContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 100px auto 0;
  gap: 3rem;

  @media only screen and (max-width: 1300px) {
    width: 90%;
    gap: 3rem;
  }

  .gatsby-image-wrapper,
  img {
    align-self: center;
    height: 60vh !important;
    min-height: 60vh;
    max-height: 60vh;
    min-width: 60vw;
    width: 100%;

    @media only screen and (max-width: 1300px) {
      height: 40vh !important;
      min-height: 40vh;
      max-height: 40vh;
      min-width: 40vw;
    }
    @media only screen and (max-width: 700px) {
      height: 20vh !important;
      min-height: 20vh;
      max-height: 20vh;
      min-width: 20vw;
    }
  }
`
